@import '@/styles/variables.scss';
@import '@/styles/mixin.scss';

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: rgba(0, 0, 0, 0.88);
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    // --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

body {
  display: flex;
  justify-content: center;
  // color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
  background: var(--color-bg-layout, #f5f5f5);
}

@media screen and (max-width: 1440px) {
  body {
    justify-content: start;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
// }

.global-layout {
  display: flex;
  max-width: $breakpoint-max-size;
  width: 100%;
  background: $white;
  // Global Navigation Bar
  .gnb {
    width: 160px;
    min-height: 100vh;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.04);
    background: #fafafa;
    .logo {
      width: 160px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-menu-item {
    background: transparent;
  }
}

.app-info-container {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  font-size: 12px;
  max-width: 300px;
}

.app-info-bottom {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  font-size: 12px;
  background-color: rgba(240, 240, 240, 0.8);
  border: 1px solid rgba(221, 221, 221, 0.8);
  border-radius: 5px;
  padding: 5px 10px;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100% - 20px);
}

.app-info-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-info-label {
  font-weight: bold;
  margin-right: 5px;
}

.app-info-value {
  color: #666;
  margin-right: 10px;
}

.app-info-separator {
  margin: 0 5px;
  color: #999;
}

.app-info-toggle {
  background-color: rgba(224, 224, 224, 0.8);
  border: none;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 10px;

  &:hover {
    background-color: rgba(208, 208, 208, 0.8);
  }
}

.app-info-log-container {
  position: fixed;
  bottom: 50px;
  left: 10px;
  z-index: 1000;
  max-width: 500px;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(221, 221, 221, 0.8);
  border-radius: 5px;
  backdrop-filter: blur(5px);
  padding: 10px;
}

.app-info-log-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.app-info-log-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.app-info-log-hash {
  font-family: monospace;
  font-weight: bold;
  color: #0366d6;
}

.app-info-log-message {
  margin: 5px 0;
  font-weight: bold;
}

.app-info-log-body {
  margin: 5px 0;
  white-space: pre-wrap;
  font-size: 12px;
  color: #333;
}

.app-info-log-details {
  font-size: 11px;
  color: #666;
}

@for $i from 9 through 30 {
  .fontSize-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 40 {
  .marginTop-#{$i} {
    margin-top: #{$i}px;
  }
  .marginLeft-#{$i} {
    margin-left: #{$i}px;
  }
  .marginRight-#{$i} {
    margin-right: #{$i}px;
  }
  .marginBottom-#{$i} {
    margin-bottom: #{$i}px;
  }
  .marginVertical-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .paddingTop-#{$i} {
    padding-top: #{$i}px;
  }
  .paddingLeft-#{$i} {
    padding-left: #{$i}px;
  }
  .paddingRight-#{$i} {
    padding-right: #{$i}px;
  }
  .paddingBottom-#{$i} {
    padding-bottom: #{$i}px;
  }
  .paddingVertical-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}

@for $i from 0 through 50 {
  .gap-#{$i} {
    display: grid;
    gap: #{$i}px;
  }
}

.flex {
  display: flex;

  &.flex-column {
    flex-direction: column;
  }
}

.ant-modal {
  padding-bottom: 100px;
}

.block {
  display: block;
}

.ant-modal-content {
  padding: 36px 24px !important;
}

.td-align-left {
  width: 100%;
  text-align: left;
}

.text-allipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-multiple-lines {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  color: #1677ff;
}

.ant-form-item-label {
  label {
    font-weight: 600;
  }
}
