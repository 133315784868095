.appInfoBottom {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  font-size: 12px;
  background-color: rgba(240, 240, 240, 0.8);
  border: 1px solid rgba(221, 221, 221, 0.8);
  border-radius: 5px;
  padding: 5px 10px;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100% - 20px);
}

.appInfoContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appInfoLabel {
  font-weight: bold;
  margin-right: 5px;
}

.appInfoValue {
  color: #666;
  margin-right: 10px;
}

.appInfoSeparator {
  margin: 0 5px;
  color: #999;
}

.appInfoToggle {
  background-color: rgba(224, 224, 224, 0.8);
  border: none;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 10px;

  &:hover {
    background-color: rgba(208, 208, 208, 0.8);
  }
}

.appInfoLogContainer {
  position: fixed;
  bottom: 50px;
  left: 10px;
  z-index: 1000;
  max-width: 500px;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(221, 221, 221, 0.8);
  border-radius: 5px;
  backdrop-filter: blur(5px);
  padding: 10px;
}

.appInfoLogList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.appInfoLogItem {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.appInfoLogHash {
  font-family: monospace;
  font-weight: bold;
  color: #0366d6;
}

.appInfoLogMessage {
  margin: 5px 0;
  font-weight: bold;
}

.appInfoLogBody {
  margin: 5px 0;
  white-space: pre-wrap;
  font-size: 12px;
  color: #333;
}

.appInfoLogDetails {
  font-size: 11px;
  color: #666;
}

.userInfoContent {
  padding: 10px;
  font-size: 12px;
}

.userInfoTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.userInfoItem {
  margin-bottom: 5px;
}

.userInfoLabel {
  font-weight: bold;
  margin-right: 5px;
}

.appInfoButtons {
  display: flex;
  align-items: center;
}

.userInfoFontSize {
  font-size: 12px;
}

.userInfoNestedList {
  padding-left: 20px;
  margin: 5px 0;
  list-style-type: disc;
}

.userInfoNestedItem {
  margin-bottom: 3px;
}

.userInfoValue {
  margin-left: 5px;
  word-break: break-all;
}

.appInfoLogPre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
}
